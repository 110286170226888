/* eslint-disable camelcase */
import homeConfig from './home.config';
import searchWorksConfig from './search-works.config';
import searchManuscriptConfig from './search-manuscript.config';
import searchWitnessesConfig from './search-witnesses.config';
import searchBibliografia from './search-bibliography.config';
import searchIconographiesConfig from './search-iconographies.config';
import searchToolsConfig from './search-tools.config';
import searchBiographiesConfig from './search-biographies.config';

import resourceManuscriptConfig from './resource-manuscript.config';
import resourceManuscriptContentsConfig from './resource-manuscript-contents.config';
import resourceManuscriptDetailSheetConfig from './resource-manuscript-detail-sheet.config';
import resourceManuscriptBibliographyConfig from './resource-manuscript-bibliography.config';
import resourceManuscriptCatalogingInfoConfig from './resource-manuscript-cataloging-info.config';
import resourceManuscriptWhereToFindConfig from './resource-manuscript-where-to-find.config';

import resourceToponymConfig from './resource-toponym.config';
import resourceIconographyConfig from './resource-iconography.config';
import resourceKeywordConfig from './resource-keyword.config';
import resourceWorkConfig from './resource-work.config';
import resourceWitnessConfig from './resource-witness.config';
import resourceBiographyConfig from './resource-biography.config';
import resourceModalBibliography_witConfig from './resource-modal-bibliography_wit.config';
import resourceToolConfig from './resource-tool.config';
import itineraryConfig from './itinerary.config';
import timelineConfig from './timeline.config';
import advancedSearchConfig from './advanced-search.config';
import advancedResultsConfig from './advanced-results.config';

import tabsConfig from './tabs.config';

export default {
  home: homeConfig,
  itinerary: itineraryConfig,
  timeline: timelineConfig,
  tabs: tabsConfig,
  'search-works': searchWorksConfig,
  'search-manuscript': searchManuscriptConfig,
  'search-witnesses': searchWitnessesConfig,
  'resource-biography': resourceBiographyConfig,
  'resource-tool': resourceToolConfig,
  'search-tools': searchToolsConfig,
  'search-biographies': searchBiographiesConfig,
  'resource-iconography': resourceIconographyConfig,
  'search-iconographies': searchIconographiesConfig,
  'resource-witness': resourceWitnessConfig,
  'resource-work': resourceWorkConfig,
  'resource-manuscript': resourceManuscriptConfig,
  'resource-manuscript-contents': resourceManuscriptContentsConfig,
  'resource-manuscript-detail-sheet': resourceManuscriptDetailSheetConfig,
  'resource-manuscript-bibliography': resourceManuscriptBibliographyConfig,
  'resource-manuscript-cataloging-info': resourceManuscriptCatalogingInfoConfig,
  'resource-manuscript-where-to-find': resourceManuscriptWhereToFindConfig,
  'resource-toponym': resourceToponymConfig,
  'resource-keyword': resourceKeywordConfig,
  'search-bibliografia': searchBibliografia,
  'resource-modal-bibliography_wit': resourceModalBibliography_witConfig,
  'advanced-search': advancedSearchConfig,
  'advanced-results': advancedResultsConfig
};
