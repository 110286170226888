import {
  MrSearchConfig,
  MrSearchFacetsConfig,
  MrSearchLayoutInput,
} from '@net7/boilerplate-muruca';

const facets = {
  sections: [
    {
      id: 'section-query',
      inputs: [
        {
          id: 'query',
          type: 'text',
          queryParam: true,
          delay: 500,
          schema: {
            valueType: 'string',
          },
          data: {
            id: 'query',
            placeholder: 'search#placeholder_query',
            icon: 'n7-icon-search',
            inputPayload: 'search-input',
            enterPayload: 'search-enter',
            iconPayload: 'search-icon',
          },
        },
      ],
    },
    {
      id: 'section-genres',
      header: {
        id: 'header-genres',
        data: {
          text: 'search#header_genres',
          additionalText: null,
        },
      },
      inputs: [
        {
          id: 'genres',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-languages',
      header: {
        id: 'header-languages',
        data: {
          text: 'search#header_language',
          additionalText: null,
        },
      },
      inputs: [
        {
          id: 'languages',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    // {
    //   id: 'section-names',
    //   header: {
    //     id: 'header-names',
    //     data: {
    //       text: 'search#header_names',
    //       additionalText: null,
    //     },
    //   },
    //   inputs: [
    //     {
    //       type: 'text',
    //       id: 'query-name',
    //       target: 'names',
    //       delay: 500,
    //       schema: {
    //         valueType: 'string',
    //       },
    //       data: {
    //         id: 'query-name',
    //         placeholder: 'search#placeholder_name',
    //         icon: 'n7-icon-search',
    //         inputPayload: 'search-input',
    //         enterPayload: 'search-enter',
    //         iconPayload: 'search-icon',
    //       },
    //     },
    //     {
    //       id: 'names',
    //       type: 'link',
    //       limit: 50,
    //       queryParam: true,
    //       schema: {
    //         valueType: 'string',
    //         multiple: true,
    //       },
    //       data: {
    //         links: [],
    //       },
    //     },
    //   ],
    // },
    // {
    //   id: 'section-places',
    //   header: {
    //     id: 'header-places',
    //     data: {
    //       text: 'search#header_places',
    //       additionalText: null,
    //     },
    //   },
    //   inputs: [
    //     {
    //       type: 'text',
    //       id: 'query-place',
    //       target: 'places',
    //       delay: 500,
    //       schema: {
    //         valueType: 'string',
    //       },
    //       data: {
    //         id: 'query-place',
    //         placeholder: 'search#placeholder_place',
    //         icon: 'n7-icon-search',
    //         inputPayload: 'search-input',
    //         enterPayload: 'search-enter',
    //         iconPayload: 'search-icon',
    //       },
    //     },
    //     {
    //       id: 'places',
    //       type: 'link',
    //       limit: 50,
    //       queryParam: true,
    //       schema: {
    //         valueType: 'string',
    //         multiple: true,
    //       },
    //       data: {
    //         links: [],
    //       },
    //     },
    //   ],
    // },
    // {
    //   id: 'section-bibl',
    //   header: {
    //     id: 'header-bibl',
    //     data: {
    //       text: 'search#header_bibl',
    //       additionalText: null,
    //     },
    //   },
    //   inputs: [
    //     {
    //       type: 'text',
    //       id: 'query-bibl',
    //       target: 'bibl',
    //       delay: 500,
    //       schema: {
    //         valueType: 'string',
    //       },
    //       data: {
    //         id: 'query-bibl',
    //         placeholder: 'search#placeholder_bibl',
    //         icon: 'n7-icon-search',
    //         inputPayload: 'search-input',
    //         enterPayload: 'search-enter',
    //         iconPayload: 'search-icon',
    //       },
    //     },
    //     {
    //       id: 'bibl',
    //       type: 'link',
    //       limit: 50,
    //       queryParam: true,
    //       schema: {
    //         valueType: 'string',
    //         multiple: true,
    //       },
    //       data: {
    //         links: [],
    //       },
    //     },
    //   ],
    // },
  ],
  classes: 'facets-wrapper',
} as MrSearchFacetsConfig;

const layoutInputs = ['page', 'limit', 'sort'].map((id) => ({
  id,
  queryParam: true,
  schema: {
    valueType: id === 'sort' ? 'string' : 'number',
  },
})) as MrSearchLayoutInput[];

const request = {
  results: {
    id: 'search',
    delay: 500,
  },
  facets: {
    id: 'facets',
  },
  provider: 'rest',
  delay: 500,
};

export default { request, facets, layoutInputs } as MrSearchConfig;
