export default {
  manoscritto: [
    {
      id: 'contents',
      label: 'tabs#manuscript_contents'
    },
    {
      id: 'detail-sheet',
      label: 'tabs#manuscript_details'
    },
    {
      id: 'bibliography',
      label: 'tabs#manuscript_bibliography'
    },
    {
      id: 'cataloging-info',
      label: 'tabs#manuscript_cataloging_info'
    },
    {
      id: 'where-to-find',
      label: 'tabs#manuscript_where_to_find'
    }
  ]
};
