import { ConfigMurucaResourceLayout } from '@net7/boilerplate-muruca';

export default {
  title: 'Manoscritto',
  type: 'manuscript',
  sections: {
    top: [
      {
        id: 'header',
        type: 'title',
        grid: null,
      },
      {
        id: 'image-viewer',
        type: 'viewer',
        grid: 3,
        tools: true,
        title: 'resource#image-viewer',
      },
      {
        id: 'tab-bar',
        type: 'tabs'
      }
    ],
    content: [],
  },
} as ConfigMurucaResourceLayout;
