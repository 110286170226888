import { ConfigMurucaResourceLayout } from '@net7/boilerplate-muruca';

export default {
  maxHeight: 100, // Threshold where the "read-more" button appears
  title: 'Manoscritto',
  tabs: 'manoscritto',
  type: 'manuscript',
  sections: {
    top: [
      {
        id: 'header',
        type: 'title',
        grid: null,
      },
      {
        id: 'image-viewer',
        type: 'viewer',
        grid: 3,
        tools: true,
        title: 'resource#image-viewer',
      },
      {
        id: 'image-viewer-iiif',
        type: 'viewer-iiif',
        title: 'resource#image-viewer-iiif',
        options: {
          libOptions: {
            window: {
              sideBarOpenByDefault: false,
              allowClose: false,
              allowMaximize: true,
              defaultSideBarPanel: 'info',
              views: [
                { key: 'single' },
                { key: 'gallery' },
              ],
              workspaceControlPanel: {
                enabled: false,
              },
            },
            id: 'mirador-container',
          },
        }
      },
      {
        id: 'tab-bar',
        type: 'tabs'
      }
    ],
    content: [
      {
        id: 'metadata-contenuti',
        type: 'metadata-dynamic',
        options: {
          accordion: true,
          dynamic: true
        }
      },
    ],
  },
} as ConfigMurucaResourceLayout;