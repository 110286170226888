import { ConfigMurucaHomeLayout } from '@net7/boilerplate-muruca';

export default {
  title: 'Home',
  bodyClasses: '',
  sections: [
    {
      id: 'hero-main',
      type: 'hero',
      grid: null,
      options: {
        classes: 'n7-hero-main',
        background: true
      }
    },
    {
      id: 'collection-hero',
      type: 'collection',
      grid: 3,
      options: {
        classes: 'is-overlay overlay_bottom',
        itemPreview: {
          limit: null,
          striptags: false
        }
      }
    },
    {
      id: 'content-main',
      type: 'hero',
      grid: null,
      options: {
        classes: 'n7-hero-works',
        background: true,
      },
    },
  ],
} as ConfigMurucaHomeLayout;
