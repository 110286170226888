import {
  MrSearchConfig,
  MrSearchFacetsConfig,
  MrSearchLayoutInput,
} from '@net7/boilerplate-muruca';

const facets = {
  sections: [
    {
      id: 'section-query',
      inputs: [
        {
          id: 'query',
          type: 'text',
          queryParam: true,
          delay: 500,
          schema: {
            valueType: 'string',
          },
          data: {
            id: 'query',
            placeholder: 'search#placeholder_query',
            icon: 'n7-icon-search',
            inputPayload: 'search-input',
            enterPayload: 'search-enter',
            iconPayload: 'search-icon',
          },
        },
      ],
    },
    // {
    //   id: 'section-years',
    //   header: {
    //     id: 'header-years',
    //     data: {
    //       text: 'search#header_years',
    //       additionalText: null
    //     }
    //   },
    //   inputs: [{
    //     id: 'years',
    //     type: 'histogram',
    //     limit: 500,
    //     queryParam: true,
    //     schema: {
    //       valueType: 'string',
    //       multiple: false,
    //     }
    //   }],
    // },
    {
      id: 'section-risorse_digitali',
      header: {
        id: 'header-risorse_digitali',
        data: {
          text: 'search#header_risorse_digitali',
          additionalText: null,
        },
      },
      inputs: [
        {
          id: 'risorse_digitali',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-biblioteca',
      header: {
        id: 'header-biblioteca',
        data: {
          text: 'search#header_library',
          additionalText: null,
        },
      },
      inputs: [
        {
          type: 'text',
          id: 'query-biblioteca',
          target: 'biblioteca',
          delay: 500,
          schema: {
            valueType: 'string',
          },
          data: {
            id: 'query-biblioteca',
            placeholder: 'search#placeholder_library',
            icon: 'n7-icon-search',
            inputPayload: 'search-input',
            enterPayload: 'search-enter',
            iconPayload: 'search-icon',
          },
        },
        {
          id: 'biblioteca',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-fondo',
      header: {
        id: 'header-fondo',
        data: {
          text: 'search#header_fund',
          additionalText: null,
        },
      },
      inputs: [
        {
          id: 'fondo',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-struttura',
      header: {
        id: 'header-struttura',
        data: {
          text: 'search#header_structure',
          additionalText: null,
        },
      },
      inputs: [
        {
          id: 'struttura',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-nomiLegatiCarteggio',
      header: {
        id: 'header-nomiLegatiCarteggio',
        data: {
          text: 'search#header_nameCart',
          additionalText: null,
        },
      },
      inputs: [
        {
          type: 'text',
          id: 'query-nomiLegatiCarteggio',
          target: 'nomiLegatiCarteggio',
          delay: 500,
          schema: {
            valueType: 'string',
          },
          data: {
            id: 'query-nomiLegatiCarteggio',
            placeholder: 'search#placeholder_name',
            icon: 'n7-icon-search',
            inputPayload: 'search-input',
            enterPayload: 'search-enter',
            iconPayload: 'search-icon',
          },
        },
        {
          id: 'nomiLegatiCarteggio',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-filigrana',
      header: {
        id: 'header-filigrana',
        data: {
          text: 'search#header_filigrana',
          additionalText: null,
        },
      },
      inputs: [
        {
          id: 'filigrana',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    // {
    //   id: 'section-sigilli',
    //   header: {
    //     id: 'header-sigilli',
    //     data: {
    //       text: 'search#header_sigilli',
    //       additionalText: null,
    //     },
    //   },
    //   inputs: [
    //     {
    //       id: 'sigilli',
    //       type: 'link',
    //       limit: 50,
    //       queryParam: true,
    //       schema: {
    //         valueType: 'string',
    //         multiple: true,
    //       },
    //       data: {
    //         links: [],
    //       },
    //     },
    //   ],
    // },
  ],
  classes: 'facets-wrapper',
} as MrSearchFacetsConfig;

const layoutInputs = ['page', 'limit', 'sort'].map((id) => ({
  id,
  queryParam: true,
  schema: {
    valueType: id === 'sort' ? 'string' : 'number',
  },
})) as MrSearchLayoutInput[];

const request = {
  results: {
    id: 'search',
    delay: 500,
  },
  facets: {
    id: 'facets',
  },
  provider: 'rest',
  delay: 500,
};

export default { request, facets, layoutInputs } as MrSearchConfig;
